
import Vue from "vue";
import { mapGetters } from "vuex";

import DefaultLayout from "@/layouts/Default.vue";

const TimeUpdater = () => import("@/components/renderless/TimeUpdater.vue");
import RefreshPage from "@/components/RefreshPage.vue";

import { MODE } from "@/app.config";

export default Vue.extend({
  name: "App",
  components: {
    RefreshPage,
    TimeUpdater,
  },
  metaInfo() {
    // must assign reactive data to local var first
    // src: https://vue-meta.nuxtjs.org/guide/caveats.html#reactive-variables-in-template-functions
    const countryCodeDisplay = this.countryCodeDisplay;
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${
        countryCodeDisplay ? `${countryCodeDisplay} ` : ""
      }Company Contact Details`,
      // all titles will be injected into this template
      titleTemplate: "%s | CompanyDirectory.net",
    };
  },
  computed: {
    ...mapGetters(["countryCodeDisplay"]),
    layout() {
      return this.$route?.meta?.layout || DefaultLayout;
    },
  },
  created() {
    if (MODE === "ext") {
      document.body.classList.add("mode-ext");
    }
  },
});
