import router from "@/router";

interface Entity {
  contacts: any[];
  images: any[];
  methods: {
    [key: string]: any;
  };
  name: string;
  object_type: string;
  object_display_name: string;
}

interface Item {
  description: string;
  menu?: Item[];
  option: number;
  wait: number;
}

interface Link {
  "@L": string;
  description: string | null;
  description_default: string;
  method_type: string;
  numObject: Entity;
}

// src: https://stackoverflow.com/q/3446170/827129
export const escapeRegExp = (str: string): string => {
  // $& means the whole matched string
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const textIsPartialPhoneNumber = (str: string): boolean => {
  const regex = new RegExp(/^[()+\d\s.-]*$/);
  return regex.test(str);
};

export const getTelephoneCountFromLink = (link: Link): number => {
  let count = 0;
  const contacts = link?.numObject?.contacts;

  if (contacts) {
    for (const contact of contacts) {
      if (contact.method_type === "link")
        count += getTelephoneCountFromLink(contact);
      else if (contact.method_type === "telephone") count += 1;
    }
  }

  return count;
};

export const getMenuTreeEndsFromItem = (item: Item): number => {
  if (item.menu) {
    let count = 0;

    for (const menuItem of item.menu) {
      if (menuItem.menu) count += getMenuTreeEndsFromItem(menuItem);
      else count += 1;
    }

    return count;
  }

  return 1;
};

// src: https://stackoverflow.com/a/21742107/827129
export const getMobileOperatingSystem = () => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return undefined;
};

export const getCountryCodeDisplay = (
  countryCode: string | null,
): string | null => {
  const displayMap: { [key: string]: string } = {
    gb: "UK",
  };
  if (!countryCode) return null;
  return (displayMap[countryCode] || countryCode).toUpperCase();
};

export const redirectToVersion = (countryCode: string): void => {
  router.push({ name: `home-${countryCode}` });
};
