import Vue from "vue";
import VueMeta from "vue-meta";
import { VuePlausible } from "vue-plausible";

import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";

import { STAGE } from "@/app.config";

import {
  directivesClickOutside,
  utilsConfigureValidation,
} from "@num/component-library";
const configureValidation = utilsConfigureValidation.default;

configureValidation();

Vue.config.devtools = STAGE !== "prod";
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/];
Vue.directive("click-outside", directivesClickOutside);

Vue.use(VueMeta);

if (STAGE === "prod") {
  Vue.use(VuePlausible, {
    apiHost: "https://companydirectory.net",
    domain: "companydirectory.uk", // needs manual transfer to update this
  });

  Vue.$plausible.enableAutoPageviews();
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
