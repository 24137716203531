import axios, { AxiosError, AxiosResponse } from "axios";
import moment, { Moment } from "moment";
import Vue from "vue";
import Vuex, { GetterTree, MutationTree, ActionTree } from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import { GET_JSON_COMPANY_RESULTS } from "@/store/types/actions";
import {
  SET_COMPANY_RESULT,
  SET_COUNTRY_CODE_IP,
  SET_COUNTRY_CODE_ROUTE,
  SET_COUNTRY_CODE_USER_SELECTED,
  SET_DNS_ENVIRONMENT,
  SET_IP_ADDRESS_PROMPT_DISMISSED,
  SET_JSON_COMPANY_RESULTS,
  SET_JSON_COMPANY_RESULTS_ABORT_CONTROLLER,
  SET_JSON_COMPANY_RESULTS_COUNTRY_CODE,
  SET_MODULES_ENVIRONMENT,
  SET_TIME_NOW,
} from "@/store/types/mutations";

import { Result } from "@/types/index";
import { DATA_URL, STAGE } from "@/app.config";
import { getCountryCodeDisplay } from "@/utils/utils";

const defaultStageToUse = STAGE === "local" ? "test" : STAGE;

interface Results {
  [key: string]: Result;
}

interface Settings {
  countryCodeIp: string | null;
  countryCodeRoute: string | null;
  countryCodeUserSelected: string | null;
  dnsEnvironment: string;
  ipAddressPromptDismissed: boolean;
  modulesEnvironment: string;
}

class State {
  companyResults: Results = {};
  jsonCompanyResults: Results | null = null;
  jsonCompanyResultsAbortController: AbortController | null = null;
  jsonCompanyResultsCountryCode: string | null = null;
  settings: Settings = {
    countryCodeIp: null,
    countryCodeRoute: null,
    countryCodeUserSelected: null,
    dnsEnvironment: defaultStageToUse,
    ipAddressPromptDismissed: false,
    modulesEnvironment: defaultStageToUse,
  };
  timeNow: Moment = moment().startOf("minute");
}

const getters = <GetterTree<State, any>>{
  countryCode: (state) =>
    state.settings.countryCodeRoute ||
    state.settings.countryCodeUserSelected ||
    state.settings.countryCodeIp,
  countryCodeDisplay: (state, getters) => {
    return getCountryCodeDisplay(getters.countryCode);
  },
  countryHomePath: (state, getters) => {
    return `/${getters.countryCode || ""}`;
  },
  getCompanyResult:
    (state) =>
    (domain: string): Result => {
      return state.companyResults[domain];
    },
};

const mutations = <MutationTree<State>>{
  [SET_COMPANY_RESULT](state, { domain, result }) {
    Vue.set(state.companyResults, domain, result);
  },
  [SET_COUNTRY_CODE_IP](state, countryCode) {
    Vue.set(state.settings, "countryCodeIp", countryCode);
  },
  [SET_COUNTRY_CODE_ROUTE](state, countryCode) {
    Vue.set(state.settings, "countryCodeRoute", countryCode);
  },
  [SET_COUNTRY_CODE_USER_SELECTED](state, countryCode) {
    Vue.set(state.settings, "countryCodeUserSelected", countryCode);
  },
  [SET_DNS_ENVIRONMENT](state, dnsEnvironment) {
    Vue.set(state.settings, "dnsEnvironment", dnsEnvironment);
    // also wipe company results when changing modules environment
    Vue.set(state, "companyResults", {});
  },
  [SET_IP_ADDRESS_PROMPT_DISMISSED](state) {
    Vue.set(state.settings, "ipAddressPromptDismissed", true);
  },
  [SET_JSON_COMPANY_RESULTS](state, results) {
    Vue.set(state, "jsonCompanyResults", results);
  },
  [SET_JSON_COMPANY_RESULTS_ABORT_CONTROLLER](state, results) {
    Vue.set(state, "jsonCompanyResultsAbortController", results);
  },
  [SET_JSON_COMPANY_RESULTS_COUNTRY_CODE](state, results) {
    Vue.set(state, "jsonCompanyResultsCountryCode", results);
  },
  [SET_MODULES_ENVIRONMENT](state, modulesEnvironment) {
    Vue.set(state.settings, "modulesEnvironment", modulesEnvironment);
    // also wipe company results when changing modules environment
    Vue.set(state, "companyResults", {});
  },
  [SET_TIME_NOW](state) {
    // round to the nearest minute to stop it being a second or so off
    Vue.set(state, "timeNow", moment().startOf("minute"));
  },
};

const actions = <ActionTree<State, any>>{
  [GET_JSON_COMPANY_RESULTS]({ commit, getters, state }) {
    if (state.jsonCompanyResultsCountryCode !== getters.countryCode) {
      commit(SET_JSON_COMPANY_RESULTS_COUNTRY_CODE, getters.countryCode);
      commit(SET_JSON_COMPANY_RESULTS, null);

      // make sure to cancel any existing requests, in case earlier ones return
      // after later ones are requested
      state.jsonCompanyResultsAbortController?.abort();
      const controller = new AbortController();
      commit(SET_JSON_COMPANY_RESULTS_ABORT_CONTROLLER, controller);

      const config = {
        // force cache to fetch each time
        // src: https://stackoverflow.com/a/68027951/827129
        params: { t: new Date().getTime() },
        signal: controller.signal,
      };

      axios
        .get(
          `${DATA_URL}/${getters.countryCode}/company-api-results.json`,
          config,
        )
        .then(async (successfulResponse: AxiosResponse) => {
          commit(SET_JSON_COMPANY_RESULTS, successfulResponse.data);
          // now the results have returned, we don't need the abort controller
          commit(SET_JSON_COMPANY_RESULTS_ABORT_CONTROLLER, null);

          return successfulResponse;
        })
        .catch((error: AxiosError) => error.response);
    }
  },
};

export default new Vuex.Store({
  state: new State(),
  getters,
  mutations,
  actions,
  plugins: [
    createPersistedState({
      key: "num-company-directory",
      paths: ["settings"],
    }),
  ],
});
