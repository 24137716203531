
import Vue from "vue";
import { Hamburger } from "@num/component-library";

import CDLogo from "@/components/CDLogo.vue";
import Container from "@/components/Container.vue";
// split off into own file and load separately due to axios package blocking initial load
const CountrySwitcher = () => import("@/components/CountrySwitcher.vue");

export default Vue.extend({
  name: "CDNav",
  components: {
    CDLogo,
    Container,
    CountrySwitcher,
    Hamburger,
  },
  data() {
    return {
      menuVisible: false,
    };
  },
  methods: {
    clickedOutside() {
      this.menuVisible = false;
    },
    hideMenu() {
      this.menuVisible = false;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
});
