// @ts-nocheck
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

const About = () => import("@/views/About.vue");
const Add = () => import("@/views/Add.vue");
const Category = () => import("@/views/Category.vue");
const Company = () => import("@/views/Company.vue");
const CountryIndex = () => import("@/views/CountryIndex.vue");
const Developers = () => import("@/views/Developers.vue");
const Home = () => import("@/views/Home.vue");
const Install = () => import("@/views/Install.vue");
const NotFound = () => import("@/views/404");
const Popup = () => import("@/views/Popup.vue");
const Privacy = () => import("@/views/Privacy.vue");
const Settings = () => import("@/views/Settings.vue");

import { STAGE } from "@/app.config";

Vue.use(VueRouter);

const requiresNotProd = (to, from, next) => {
  if (STAGE !== "prod") {
    next();
  } else {
    next({ name: "home" });
  }
};

const routes: Array<RouteConfig> = [
  {
    path: "/popup.html",
    name: "popup",
    component: Popup,
  },
  {
    path: "/",
    name: "home-country-index",
    component: CountryIndex,
  },
  {
    path: "/gb",
    name: "home-gb",
    component: Home,
  },
  {
    path: "/us",
    name: "home-us",
    component: Home,
  },
  {
    // if the param is just letters it's a category not a domain
    // src: https://github.com/vuejs/vue-router/blob/73459565c4c64bfe9b41341875a84c87bc8b931c/examples/route-matching/app.js#L18
    path: "/:categoryId([a-z]+)/contact-information",
    name: "category",
    component: Category,
  },
  {
    path: "/:companyDomain/contact-information",
    name: "company",
    component: Company,
  },
  {
    path: "/about-us",
    name: "about",
    component: About,
  },
  {
    path: "/add-update-company",
    name: "add",
    component: Add,
  },
  {
    path: "/developers",
    name: "developers",
    component: Developers,
  },
  {
    path: "/install",
    name: "install",
    component: Install,
  },
  {
    beforeEnter: requiresNotProd,
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    component: NotFound,
    name: "not-found",
    path: "*",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
