export const SET_COMPANY_RESULT = "SET_COMPANY_RESULT";
export const SET_COUNTRY_CODE_IP = "SET_COUNTRY_CODE_IP";
export const SET_COUNTRY_CODE_ROUTE = "SET_COUNTRY_CODE_ROUTE";
export const SET_COUNTRY_CODE_USER_SELECTED = "SET_COUNTRY_CODE_USER_SELECTED";
export const SET_DNS_ENVIRONMENT = "SET_DNS_ENVIRONMENT";
export const SET_IP_ADDRESS_PROMPT_DISMISSED =
  "SET_IP_ADDRESS_PROMPT_DISMISSED";
export const SET_JSON_COMPANY_RESULTS = "SET_JSON_COMPANY_RESULTS";
export const SET_JSON_COMPANY_RESULTS_ABORT_CONTROLLER =
  "SET_JSON_COMPANY_RESULTS_ABORT_CONTROLLER";
export const SET_JSON_COMPANY_RESULTS_COUNTRY_CODE =
  "SET_JSON_COMPANY_RESULTS_COUNTRY_CODE";
export const SET_MODULES_ENVIRONMENT = "SET_MODULES_ENVIRONMENT";
export const SET_TIME_NOW = "SET_TIME_NOW";
