
import Vue from "vue";
import { mapGetters } from "vuex";

import Logo from "@/assets/logo.svg";

export default Vue.extend({
  name: "CDLogo",
  components: {
    Logo,
  },
  props: {
    color: String,
    size: String,
  },
  computed: {
    ...mapGetters(["countryHomePath"]),
    imageClass() {
      if (this.size === "small") return "h-8 lg:h-10";
      if (this.size === "large") return "h-10 md:h-12 lg:h-14";
      else return "h-8 md:h-10 lg:h-12";
    },
  },
});
