// @ts-nocheck
/* eslint-disable no-console */

import { register } from "register-service-worker";

import { MODE } from "@/app.config";

const currentUrl = window.location.hostname;

if (process.env.NODE_ENV === "production" && MODE !== "ext") {
  // the server should handle redirects, but if we've got this far then we're
  // using a version cached by the service worker from previous visits to old
  // domains, so immediately redirect with JS
  if (currentUrl === "companydirectory.redirect.test.numops.com") {
    location.href = "https://companydirectory.test.numops.com/gb";
  } else if (currentUrl === "companydirectory.redirect.staging.numops.com") {
    location.href = "https://companydirectory.staging.numops.com/gb";
  } else if (currentUrl === "companydirectory.uk") {
    location.href = "https://companydirectory.net/gb";
  }

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB",
      );
    },
    registered(registration) {
      console.log("Service worker has been registered.");

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 5); // check every 5 minutes
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration }),
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode.",
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });

  self.addEventListener("message", (event) => {
    if (event.data && event.data.type === "SKIP_WAITING") {
      self.skipWaiting();
    }
  });
}
