
import Vue from "vue";

// lazy load to remove initial bundle including moment-js for faster load times
const CDFooter = () => import("@/components/CDFooter.vue");
import CDNav from "@/components/CDNav.vue";

import { MODE } from "@/app.config";

export default Vue.extend({
  name: "DefaultLayout",
  components: {
    CDFooter,
    CDNav,
  },
  data() {
    return {
      MODE,
    };
  },
});
